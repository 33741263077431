import { WebGLRenderer } from 'three/src/renderers/WebGLRenderer';
import { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera';
import { Clock } from 'three/src/core/Clock';
import { Vector2 } from 'three/src/math/Vector2';
import { Vector3 } from 'three/src/math/Vector3';
import { Scene } from 'three/src/scenes/Scene';
import { EventDispatcher } from 'three/src/core/EventDispatcher';
import { Raycaster } from 'three/src/core/Raycaster';
import * as THREE from 'three';

import raf from 'raf';
import touches from 'touches';
import debounce from 'just-debounce-it';

import OrbitControls from './utils/controls/OrbitControls';
import Sphere from './Sphere';
import Lights from './Lights';

import canUseWebP from '../../../utils/canUseWebP';
import assets from '../../../utils/asset-manager';
import device from '../../../utils/device';
import Inertia from '../../../utils/Inertia';

canUseWebP();

export const LOADING = 'LOADING';
export const LOADED = 'LOADED';
export const PROGRESS = 'PROGRESS';

export const MIN_RATIO = 0.8;
export const MAX_RATIO = 1.8;

export const MIN_FOV = 45;
export const NORMAL_FOV = 60;
export const MAX_FOV = 70;

export const ACTIVE_FOG_NEAR = 10;
export const ACTIVE_FOG_FAR = 20;
export const FOG_OFFSET = 20;

export const CLEAR_COLOR = 0xf2f2f2;
export const FOG_COLOR = CLEAR_COLOR;

export const SCENE_RADIUS = 128;
export const MIN_DISTANCE = 1;
export const MAX_DISTANCE = 350;

export const CAMERA_FOV = 12;
export const CAMERA_FOV_MOBILE = 18;
export const CAMERA_DEFAULT_POSITION = {
  x: 0,
  y: 0 - 1,
  z: 250,
  lookAt: {
    x: 0,
    y: 0 - 1,
    z: -30,
  },
};
export const CAMERA_POINTER_MOVEMENT_X = 60;
export const CAMERA_POINTER_MOVEMENT_Y = 40;

export const MOBILE_THRESHOLD = 960;

export const USE_POSTPROCESSING = false;
export const USE_ANTIALIASING = true;

let DPR = MIN_RATIO;

export const getDPR = () => DPR;

export const setDPR = (ratio = DPR, respectMax = true) => {
  if (respectMax) {
    DPR = Math.min(ratio, MAX_RATIO);

    if (device.browser === 'IE') {
      DPR = MIN_RATIO;
    }
  } else {
    DPR = ratio;
  }
};

setDPR(global.devicePixelRatio);

const defaults = {
  container: typeof window !== 'undefined' ? window.document.body : null,
};

/**
 * Set all Object3D instances as static by default.
 */
// Object3D.DefaultMatrixAutoUpdate = false;

/**
 * This is the World class.
 *
 * @author Bjørn Fjellstad <bjorn@apt.no>
 */
export default class World extends EventDispatcher {
  container = null;

  clock = null;
  elapsed = 0;

  screenDimensions = new Vector2(1, 1);
  resolution = new Vector2(1, 1);

  scene = null;
  camera = null;
  cameraScenePosition = {
    x: CAMERA_DEFAULT_POSITION.x,
    y: CAMERA_DEFAULT_POSITION.y,
    z: CAMERA_DEFAULT_POSITION.z,
  };
  zoomAt = new Vector3(
    CAMERA_DEFAULT_POSITION.lookAt.x,
    CAMERA_DEFAULT_POSITION.lookAt.y,
    CAMERA_DEFAULT_POSITION.lookAt.z
  );
  cameraMovementStrength = 0.5;
  fogAmount = 0;

  renderer = null;
  composer = null;

  controls = null;

  lights = null;
  city = null;

  pointer = new Vector2(0, 0);
  pointerDrag = new Vector2();
  pointerStart = new Vector2();
  pointerStartNormalized = new Vector2();
  pointerInertiaPosition = {
    x: new Inertia(-1, 1, 0.18, 0.18),
    y: new Inertia(-1, 1, 0.18, 0.18),
  };
  dragInertiaPosition = {
    x: new Inertia(-1, 1, 0.16, 0.16),
    y: new Inertia(-1, 1, 0.16, 0.16),
  };
  dragNormalizedDeltaInertia = {
    // x: new Inertia(-1, 1, 0.16, 0.16),
    y: new Inertia(-1, 1, 0.16, 0.16),
  };
  lastDragDelta = {
    x: 0,
    y: 0,
  };
  scrollDeltaY = 0;
  scrollNormalizedDeltaInertia = {
    x: new Inertia(-1, 1, 0.16, 0.16),
    y: new Inertia(-1, 1, 0.16, 0.16),
  };
  scrollAbsoluteY = 0;
  scrollAbsoluteInertia = {
    x: new Inertia(-10000000, 10000000, 0.2, 0.2),
    y: new Inertia(-10000000, 10000000, 0.2, 0.2),
  };
  lastScrollAbsolute = {
    x: 0,
    y: 0,
  };
  lastTouch = 0;
  lastScrollTop = 0;

  transitionProgress = 0;

  raycaster = new Raycaster();
  intersect = null;
  clickableObjects = [];

  loading = false;
  loaded = false;

  running = false;

  updateId = null;

  tanFOV = Math.tan(((Math.PI / 180) * NORMAL_FOV) / 2);

  coords = new Vector3();

  /**
   * Create Scene.
   */
  constructor(params) {
    super();

    const options = {
      ...defaults,
      ...params,
    };

    this.sceneDispatch = options.sceneDispatch;
    this.history = options.history;
    this.container = options.container;
    this.innovationPlatforms = options.innovationPlatforms;
    const { offsetWidth: width, offsetHeight: height } = this.container;
    this.width = width;
    this.height = height;
    this.isMobile = this.width < MOBILE_THRESHOLD;

    this.screenDimensions.set(width, height);
    this.resolution.set(width * DPR, height * DPR);
    this.clock = new Clock(false);

    this.camera = new PerspectiveCamera(
      CAMERA_FOV,
      width / height,
      0.1,
      SCENE_RADIUS + MAX_DISTANCE
    );
    this.camera.position.set(0, 0, 185);

    this.scene = new Scene();
    // this.scene.fog = new THREE.Fog(FOG_COLOR, 3, 9);
    // this.scene.fog.far = 40;

    this.renderer = this.initRenderer(width, height);
    // const context = this.renderer.getContext();
    // const debugInfo = context.getExtension('WEBGL_debug_renderer_info');
    // const renderer = context.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    // console.log(renderer);

    this.renderer.setClearColor(CLEAR_COLOR);

    assets.setRenderer(this.renderer);
    this.container.appendChild(this.renderer.domElement);

    // if (USE_POSTPROCESSING) {
    //   this.setupPostprocessing(width, height);
    // }

    this.lights = new Lights();
    this.scene.add(this.lights);

    this.sphere = new Sphere();
    this.scene.add(this.sphere);

    this.bindEvents();

    this.scrollAbsoluteInertia.x.setValue(0);
    this.scrollAbsoluteInertia.y.setValue(0);

    this.scrollNormalizedDeltaInertia.x.setValue(0);
    this.scrollNormalizedDeltaInertia.y.setValue(0);

    this.dragNormalizedDeltaInertia.y.setValue(0);
  }

  initRenderer(width, height) {
    const renderer = (this.renderer = new WebGLRenderer({
      antialias: USE_ANTIALIASING,
      // Only needed if we need to store the canvas as an image at some point.
      // preserveDrawingBuffer: true,
    }));

    renderer.setSize(width, height);
    renderer.setPixelRatio(DPR);

    // renderer.gammaFactor = 2.2;
    // renderer.gammaOutput = false;
    // renderer.gammaInput = false;

    renderer.outputEncoding = THREE.sRGBEncoding;
    // renderer.physicallyCorrectLights = true;

    // renderer.toneMapping = LinearToneMapping;
    // renderer.toneMappingExposure = 1.0;

    // renderer.shadowMap.enabled = true;
    // renderer.shadowMap.type = PCFSoftShadowMap;

    return renderer;
  }

  // setupPostprocessing(width, height) {
  //   let renderTarget;
  //   this.composer = new EffectComposer(this.renderer, renderTarget);

  //   const renderPass = new RenderPass(this.scene, this.camera);
  //   this.composer.addPass(renderPass);

  //   this.finalPass = new ShaderPass(FinalShader);
  //   this.composer.addPass(this.finalPass);

  //   // this.finalColorPass = new ShaderPass(FinalColorShader);
  //   // this.composer.addPass(this.finalColorPass);
  // }

  initControls(camera, container, debug) {
    const controls = new OrbitControls(camera, container);

    controls.minDistance = MIN_DISTANCE;
    controls.maxDistance = MAX_DISTANCE;

    controls.rotateSpeed = 0.1;

    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    controls.enablePan = debug;
    controls.enableKeys = debug;
    controls.autoRotate = false;
    controls.autoRotateSpeed = -0.03;

    controls.target.copy(new Vector3(0, 0, 0));

    return controls;
  }

  /**
   * Bind DOM events.
   */
  bindEvents() {
    global.addEventListener('resize', this.resize, false);
    global.addEventListener('blur', this.handleBlur);
    global.addEventListener('focus', this.handleFocus);
    global.addEventListener('keydown', this.handleKeydown);
    // global.addEventListener('mousewheel', this.handleWheel);
    // global.addEventListener('DOMMouseScroll', this.handleWheel);
    global.addEventListener('scroll', this.handleWheel);

    this.touches = touches(document.body, {
      filtered: true,
      preventSimulated: false,
      // passive: false,
    })
      .on('start', this.handleTouchStart)
      .on('move', this.handleTouchMove)
      .on('end', this.handleTouchEnd);
  }

  unbindEvents() {
    global.removeEventListener('resize', this.resize, false);
    global.removeEventListener('blur', this.handleBlur);
    global.removeEventListener('focus', this.handleFocus);
    global.removeEventListener('keydown', this.handleKeydown);
    // global.removeEventListener('mousewheel', this.handleWheel);
    // global.removeEventListener('DOMMouseScroll', this.handleWheel);
    global.removeEventListener('scroll', this.handleWheel);

    this.touches.disable();
  }

  handleBlur = () => {
    // if (this.updateId) {
    //   this.blurred = true;
    // }
  };

  handleFocus = () => {
    // this.blurred = false;
    // if (this.running) {
    //   this.stop();
    //   this.start();
    // }
  };

  handleKeydown = (e) => {
    if (!this.running || this.blurred) return;

    // if (e.key === 'Enter' || e.key === ' ') {

    // }
  };

  /**
   * Load assets.
   */
  load = () =>
    new Promise((resolve) => {
      this.dispatchEvent({ type: LOADING });

      this.loading = true;
      const onProgress = (progress) => {
        this.dispatchEvent({ type: PROGRESS, progress });
      };

      assets.addProgressListener(onProgress);

      assets.loadQueued(() => {
        assets.removeProgressListener(onProgress);

        this.loading = false;
        this.loaded = true;

        this.resize();
        this.update(true);

        setTimeout(() => {
          this.dispatchEvent({ type: LOADED });
          resolve();
        }, 0);
      });
    });

  /**
   * Start update loop and render.
   */
  start() {
    if (this.running) return;

    this.running = true;

    if (!this.updateId) this.update();

    this.clock.start();
  }

  /**
   * Stop update loop and render.
   */
  stop() {
    if (!this.running) return;

    if (this.updateId) {
      raf.cancel(this.updateId);
      this.updateId = null;
    }

    this.running = false;

    this.clock.stop();
  }

  handleTouchStart = (event, pos) => {
    if (!this.running || this.blurred) {
      return;
    }

    this.lastTouch = Date.now();

    const y = pos[1];

    this.pointerStart.x = pos[0];
    this.pointerStart.y = y;
    // this.errorContainer.textContent = `Start: ${this.pointerStart.x} / ${this.pointerStart.y}`;

    this.pointerDrag.x = (pos[0] / this.width) * 2 - 1;
    this.pointerDrag.y = -(y / this.height) * 2 + 1;
    this.pointerStartNormalized.x = this.pointerDrag.x;
    this.pointerStartNormalized.y = this.pointerDrag.y;

    this.dragInertiaPosition.x.setValue(this.pointerDrag.x);
    this.dragInertiaPosition.y.setValue(this.pointerDrag.y);
    this.lastDragDelta.x = this.pointerDrag.x;
    this.lastDragDelta.y = this.pointerDrag.y;

    this.isDragging = true;
  };

  handleTouchMove = (event, pos) => {
    if (!this.running || this.blurred) return;

    const y = pos[1];

    this.pointer.x = (pos[0] / this.width) * 2 - 1;
    this.pointer.y = -(y / this.height) * 2 + 1;

    if (this.isDragging) {
      this.pointerDrag.x = this.pointer.x;
      this.pointerDrag.y = this.pointer.y;
    }
  };

  handleTouchEnd = (event, pos) => {
    // this.errorContainer.style.background = 'crimson';

    if (!this.running || this.blurred) return;

    const y = pos[1];

    this.pointer.x = (pos[0] / this.width) * 2 - 1;
    this.pointer.y = -(y / this.height) * 2 + 1;

    // this.errorContainer.textContent = `End: ${this.pointer.x} / ${this.pointer.y}`;

    if (this.isDragging) {
      this.pointerDrag.x = this.pointer.x;
      this.pointerDrag.y = this.pointer.y;

      this.pointerStartNormalized.x = this.pointerDrag.x;
      this.pointerStartNormalized.y = this.pointerDrag.y;

      this.isDragging = false;
      this.isScrollInteracting = false;
    }

    const distance = this.pointerStart.distanceTo(new Vector2(pos[0], y));

    const isDrag = distance > 40;
    const isHold = distance < 5;
    const isFlick = Date.now() - this.lastTouch < 200 && isDrag;
    const isClick = ((!isFlick && !isDrag) || isHold) && event.which === 1; // only allow left mouse button

    if (isClick && this.running) {
      const raycastHit = this.rayCast(this.clickableObjects);
      if (raycastHit) {
      }
    }
  };

  handleWheel = (e) => {
    if (!this.running || this.blurred) return;

    // e.stopPropagation();
    // e.preventDefault();
    e = window.event || e;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const delta = scrollTop - this.lastScrollTop;
    this.lastScrollTop = scrollTop;

    // let delta = 0;
    // if (e.wheelDeltaY) {
    //   const value = e.wheelDeltaY;
    //   delta = value;
    // } else if (e.wheelDelta) {
    //   const value = e.wheelDelta;
    //   delta = value;
    // } else if (e.detail) {
    //   const value = -e.detail;
    //   const direction = Math.sign(value);
    //   delta = direction * 60;
    // }

    this.scrollAbsoluteY += delta;

    if (this.isScrolling) {
      this.scrollDeltaY += delta;
    }

    this.isScrolling = true;
    this.isScrollInteracting = true;

    const isUsingTouchPad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0;

    if (isUsingTouchPad && !this.isUsingTouchPad) {
      this.isUsingTouchPad = true;
      // this.resetIsScrollInteracting = debounce(() => {
      //   this.isScrollInteracting = false;
      // }, 150);
    }

    this.resetIsScrolling();
    this.resetIsScrollInteracting();
  };

  resetIsScrolling = debounce(() => {
    this.scrollDeltaY = 0;
    this.isScrolling = false;
  }, 500);

  resetIsScrollInteracting = debounce(() => {
    this.isScrollInteracting = false;
  }, 200);

  boundDispatch = (action) => {
    this.dispatchEvent(action);
  };

  rayCast = (target) => {
    if (!target) return null;

    this.raycaster.setFromCamera(this.pointer, this.camera);

    const intersects = Array.isArray(target)
      ? this.raycaster
          .intersectObjects(target)
          .filter((hit) => hit && hit.object && hit.object.visible)
      : this.raycaster.intersectsObject(target);

    if (intersects.length > 0) {
      const intersect = intersects[0];

      return intersect;
    }

    return null;
  };

  /**
   * Get adjusted camera FOV.
   */
  getFOV = (width, height) => {
    if (width < MOBILE_THRESHOLD) {
      return CAMERA_FOV_MOBILE;
    }

    return CAMERA_FOV;
  };

  /**
   * Eventhandler for when the global is resized.
   */
  resize = () =>
    new Promise((resolve) => {
      // console.log('resize');
      let { offsetWidth: width, offsetHeight: height } = this.container;

      setDPR(global.devicePixelRatio);

      this.width = width;
      this.height = height;
      this.isMobile = this.width < MOBILE_THRESHOLD;

      this.screenDimensions.set(width, height);
      this.resolution.set(width * DPR, height * DPR);

      this.renderer.setSize(width, height);
      this.renderer.setPixelRatio(DPR);

      this.camera.aspect = width / height;
      this.camera.fov = this.getFOV(width, height);
      this.camera.updateProjectionMatrix();

      if (this.sphere) {
        this.sphere.resize(this.screenDimensions);
      }

      if (!this.running) {
        this.update();
      }

      resolve();
    });

  /**
   * Update
   */
  update = (forced = false) => {
    if (!forced && (!this.loaded || !this.scene)) {
      this.updateId = raf(this.update);
      return;
    } else if (this.blurred) {
      return;
    }

    const delta = this.clock.getDelta();
    this.elapsed += delta;

    /* dev:start */
    if (global.sceneStats) global.sceneStats.begin();
    /* dev:end */

    // if controls is enabled
    if (this.controls && typeof this.controls.update === 'function' && this.controls.enabled) {
      this.controls.update(delta);
    } else {
      this.pointerInertiaPosition.x.update(this.pointer.x);
      this.pointerInertiaPosition.y.update(this.pointer.y);

      const inertiaPointerValue = {
        x: this.pointerInertiaPosition.x.value,
        y: this.pointerInertiaPosition.y.value,
      };

      this.scrollAbsoluteInertia.y.update(this.scrollAbsoluteY);
      const scrollAbsolute = {
        y: this.scrollAbsoluteInertia.y.value,
      };
      const scrollDelta = {
        y: (scrollAbsolute.y - this.lastScrollAbsolute.y) * -1,
      };

      this.lastScrollAbsolute.y = scrollAbsolute.y;

      this.dragInertiaPosition.x.update(this.pointerDrag.x);
      this.dragInertiaPosition.y.update(this.pointerDrag.y);

      if (this.sphere && typeof this.sphere.update === 'function') {
        this.sphere.update({
          elapsed: this.elapsed,
          delta,
          pointer: inertiaPointerValue,
          transition: this.transitionProgress,
          scrollDelta,
          movementStrength: this.cameraMovementStrength,
        });
      }

      // if (this.lights && typeof this.lights.update === 'function') {
      //   this.lights.update({
      //     elapsed: this.elapsed,
      //     delta,
      //     pointer: inertiaPointerValue,
      //   });
      // }

      this.camera.position.x =
        this.cameraScenePosition.x +
        this.pointerInertiaPosition.x.value *
          CAMERA_POINTER_MOVEMENT_X *
          this.cameraMovementStrength;

      this.camera.position.y =
        this.cameraScenePosition.y +
        this.pointerInertiaPosition.y.value *
          CAMERA_POINTER_MOVEMENT_Y *
          this.cameraMovementStrength;

      this.camera.position.z = this.cameraScenePosition.z;
      //  -
      // this.pointerInertiaPosition.x.value *
      //   CAMERA_POINTER_MOVEMENT_X *
      //   this.cameraMovementStrength;

      this.camera.lookAt(this.zoomAt);
    }

    /* dev:start */
    // if (debug) {
    //   if (this.debugMesh && typeof this.debugMesh.update === 'function') {
    //     this.debugMesh.update({
    //       elapsed: this.elapsed,
    //       delta,
    //       zoomAt: this.zoomAt,
    //     });
    //   }
    // }

    /* dev:end */

    this.scene.updateMatrixWorld();

    this.render(delta);

    /* dev:start */
    if (global.sceneStats) global.sceneStats.end();
    /* dev:end */

    if (this.running) {
      this.updateId = raf(this.update);
    }
  };

  /**
   * Render.
   */
  render(delta) {
    if (!this.renderer) return;

    // if (USE_POSTPROCESSING) {
    //   // this.postFX.render(this.scene, this.camera);
    //   this.composer.render(delta);
    // } else {
    this.renderer.render(this.scene, this.camera);
    // }

    /* dev:start */
    if (global.sceneMonitor) global.sceneMonitor.update(this.renderer);
    /* dev:end */
  }

  dispose() {
    console.log('dispose');
    this.unbindEvents();
  }
}
