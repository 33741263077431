import { Object3D } from 'three/src/core/Object3D';
import { PointLight } from 'three/src/lights/PointLight';
import { AmbientLight } from 'three/src/lights/AmbientLight';

/**
 * This is the Lights scene.
 */
export default class Lights extends Object3D {
  name = 'Lights';
  loaded = false;

  directionalLight = null;
  ambientLight = null;

  constructor() {
    super();

    this.ambientLight = new AmbientLight(0xffffff, 0.88);
    this.add(this.ambientLight);

    this.pointLight = new PointLight(0xffffff, 0.6, 500);
    this.pointLight.position.set(190, 190, 10);
    this.add(this.pointLight);

    this.loaded = true;
  }

  /**
   * Update Lights.
   */
  update = ({ elapsed, delta, pointer }) => {
    if (!this.loaded) return;

    // this.pointLight.position.x = pointer.x * 200;
    // this.pointLight.position.y = pointer.y * 200;
    // console.log(this.pointLight.position);
  };

  /**
   * Render Lights.
   */
  render() {}

  /**
   * Dispose Lights.
   */
  dispose() {
    console.log('Dispose Lights');
  }
}
