/**
 * This file is part of the SIP application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Texture } from 'three/src/textures/Texture';
import {
  LinearEncoding,
  RGBFormat,
  RGBAFormat,
  sRGBEncoding,
  ClampToEdgeWrapping,
} from 'three/src/constants';

// import loadImage from 'load-img';
import { loadImage } from './load-image';

const noop = () => {};

const loadTexture = (src, opt, cb) => {
  if (typeof opt === 'function') {
    cb = opt;
    opt = {};
  }
  opt = Object.assign({}, opt);
  cb = cb || noop;

  const texture = new Texture();
  texture.name = src;

  texture.encoding = opt.encoding || LinearEncoding;

  setTextureParams(src, texture, opt);

  loadImage(src)
    .then(image => {
      texture.image = image;
      // texture.needsUpdate = true;

      if (opt.renderer) {
        // Force texture to be uploaded to GPU immediately,
        // this will avoid "jank" on first rendered frame
        opt.renderer.initTexture(texture);
      }

      cb(null, texture);
    })
    .catch(cb);
  // .catch(err => {
  //   // const msg = `Could not load texture ${src}`;
  //   // console.error(msg);
  //   // cb(msg);
  //   // cb(new Error(msg, err));
  //   // throw erro
  //   // return cb(new Error(msg, err));
  // });

  return texture;
};

export const setTextureParams = (url, texture, opt) => {
  const { repeat, center, offset, ...rest } = opt;

  const params = {
    ...rest,
    wrapS: opt.wrapS || ClampToEdgeWrapping,
    wrapT: opt.wrapT || ClampToEdgeWrapping,
  };

  const isJPEG =
    url.search(/\.(jpg|jpeg)$/) > 0 || url.search(/^data:image\/jpeg/) === 0;

  params.format = opt.format || (isJPEG ? RGBFormat : RGBAFormat);
  params.encoding = opt.encoding || sRGBEncoding;

  if (repeat) texture.repeat.copy(repeat);
  if (center) texture.center.copy(center);
  if (offset) texture.offset.copy(offset);

  Object.assign(texture, params);
};

// export
export default loadTexture;
