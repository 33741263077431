/**
 * This file is part of the SIP application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { isDataURL } from './index';
import { getWorkerBlob, getWorkerArrayBuffer } from './file-loader-worker';

const { location } = global;

const WORKER_ENABLED = !!(global.URL && global.Blob && global.Worker);

const loadImg = src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = src;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(src);
    };
  });

const loadBuffer = src =>
  fetch(src, { mode: 'cors', credentials: 'same-origin' }).then(response =>
    response.arrayBuffer()
  );

const loadWorker = (workerFn, loadFn) => src =>
  isDataURL(src)
    ? loadFn(src)
    : new Promise((resolve, reject) => {
        function handler(e) {
          if (e.data.src.indexOf(src) !== -1) {
            workerFn.removeEventListener('message', handler);
            if (e.data.error) {
              reject(e.data.error);
            }

            if (e.data.blob) {
              const img = new Image();

              img.crossOrigin = 'Anonymous';
              img.src = e.data.blob;

              const onLoad = () => {
                URL.revokeObjectURL(e.data.blob);
                resolve(img);
              };

              const onError = () => {
                reject(img);
              };

              // if (typeof img.decode !== 'undefined') {
              //   img
              //     .decode()
              //     .then(onLoad)
              //     .catch(onError);
              // } else {
              //   img.onload = onLoad;
              //   img.onerror = onError;
              // }

              img.onload = onLoad;
              img.onerror = onError;
            }

            if (e.data.bitmap) {
              resolve(e.data.bitmap);
            }

            if (e.data.buffer) {
              resolve(e.data.buffer);
            }
          }
        }
        workerFn.addEventListener('message', handler);
        workerFn.postMessage(`${location.origin}${src}`);
      });

export const loadImage = WORKER_ENABLED
  ? loadWorker(getWorkerBlob, loadImg)
  : loadImg;

export const loadImageBuffer = WORKER_ENABLED
  ? loadWorker(getWorkerArrayBuffer, loadBuffer)
  : loadBuffer;
