/**
 * This file is part of the SIP application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Mesh } from 'three/src/objects/Mesh';
import { Object3D } from 'three/src/core/Object3D';
import { Scene } from 'three/src/scenes/Scene';
import { Texture } from 'three/src/textures/Texture';

import mapLimit from 'map-limit';

import device from '../../utils/device';

import { loadImage } from './load-image';
// import { loadGLTF } from './load-gltf';
// import { loadFBX } from './load-fbx';
import loadTexture from './load-texture';
// import loadCompressedTexture from './load-compressed-texture';

const destroyChildMeshesFrom = (parentObject) =>
  parentObject.traverse((node) => {
    if (node.isMesh) {
      if (node.geometry) {
        node.geometry.dispose();
      }

      if (node.material) {
        if (
          node.material.forEach &&
          typeof node.material.forEach === 'function'
        ) {
          node.material.forEach((mtrl) => {
            if (mtrl.map) mtrl.map.dispose();
            if (mtrl.lightMap) mtrl.lightMap.dispose();
            if (mtrl.bumpMap) mtrl.bumpMap.dispose();
            if (mtrl.normalMap) mtrl.normalMap.dispose();
            if (mtrl.specularMap) mtrl.specularMap.dispose();
            if (mtrl.envMap) mtrl.envMap.dispose();

            mtrl.dispose();
          });
        } else {
          if (node.material.map) node.material.map.dispose();
          if (node.material.lightMap) node.material.lightMap.dispose();
          if (node.material.bumpMap) node.material.bumpMap.dispose();
          if (node.material.normalMap) node.material.normalMap.dispose();
          if (node.material.specularMap) node.material.specularMap.dispose();
          if (node.material.envMap) node.material.envMap.dispose();

          node.material.dispose();
        }
      }
    }
  });

const noop = () => {};

const isImage = (ext) =>
  /\.?(jpe?g|png|gif|webp|bmp|tga|dds|pvr|tif)$/i.test(ext);
// const isGLTF = (ext) => /\.?(gltf|glb)$/i.test(ext);
// const isFBX = ext => /\.?(fbx)$/i.test(ext);
const isSVG = (ext) => /\.?svg$/i.test(ext);
export const isDataURL = (s) =>
  /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)\s*$/i.test(
    s
  );

/**
 * https://github.com/jouni-kantola/fetch-image-worker/blob/master/js/app.js
 * https://github.com/developit/workerize-loader
 * https://github.com/webpack-contrib/worker-loader
 */

/**
 * This is the AssetManager class.
 *
 * @author Marius Nohr <marius@noth.no>
 * @author Magnus Bergman <magnus@apt.no>
 */
class AssetManager {
  /**
   * Create AssetManager.
   *
   * @param {Object} opt
   */
  constructor(opt = {}) {
    this._cache = {};
    this._queue = [];
    this._renderer = opt.renderer;
    this._asyncLimit = device.isMobile ? 4 : Infinity;
    this._onProgressListeners = [];
    this._onLoadedListeners = [];
    this._finishDelay = 0;
    this._groups = {};

    global.assets = this;
  }

  /**
   * Must be set in order for texture loading to work.
   *
   * @param {THREE.WebGLRenderer} renderer
   */
  setRenderer(renderer) {
    this._renderer = renderer;
  }

  getRenderer() {
    return this._renderer;
  }

  /**
   * Add an asset to the queue.
   *
   * @param {Object} opt
   * @param {string} opt.url
   */
  queue(opt = {}) {
    if (!opt || typeof opt !== 'object') {
      throw new Error('First parameter must be an object!');
    }

    if (!opt.url) {
      throw new TypeError(
        'Must specify a URL or opt.url for AssetManager#queue()'
      );
    }

    opt = Object.assign({}, opt);
    opt.key = opt.key || opt.url;

    const queued = this._getQueued(opt.key);

    if (!queued) {
      this._queue.push(opt);
    }

    if (queued && opt.groups) {
      if (queued.groups) {
        queued.groups.push(...opt.groups);
      } else {
        queued.groups = opt.groups;
      }
    }

    return opt.key;
  }

  /**
   * Add multiple assets as a group to the queue.
   *
   * @param {array} assets
   * @param {string} groupId
   * @param {function} callback
   */
  queueGroup(assets, groupId, callback) {
    if (this._groups[groupId]) {
      throw new TypeError(
        `Cannot add two groups with the same id with AssetManager#queueGroup() -> ${groupId}`
      );
    }

    const group = {
      loaded: 0,
      total: assets.length,
      assets: assets.map((a) => a.key || a.url),
      callback,
    };

    this._groups[groupId] = group;

    for (const asset of assets) {
      asset.groups = [groupId];
      this.queue(asset);
    }
  }

  /**
   * Retrieve an asset from the cache.
   *
   * @param {string} key
   *
   * @return {*}
   */
  get(key = '') {
    if (!key) {
      throw new TypeError('Must specify a key or URL for AssetManager#get()');
    }

    if (!(key in this._cache)) {
      console.warn(`Could not find an asset by the key or URL ${key}`);
      return null;
    }

    // if (this._cache[key] instanceof Object3D) {
    //   return this._cache[key].clone();
    // }

    return this._cache[key];
  }

  /**
   * Load all assets in the queue.
   *
   * @param {function} cb
   */
  loadQueued(cb = noop) {
    const queue = this._queue.slice();
    this._queue.length = 0; // clear queue
    let count = 0;
    let total = queue.length;

    if (total === 0) {
      setTimeout(() => {
        this._onProgressListeners.forEach((fn) => fn(1));
        cb(null);
      }, 0);

      return;
    }

    // console.log(`[assets] Loading ${total} queued items`);

    mapLimit(
      queue,
      this._asyncLimit,
      (item, next) => {
        this.load(
          item,
          (err, result) => {
            const percent = total <= 1 ? 1 : count / (total - 1);
            this._onProgressListeners.forEach((fn) => fn(percent));

            if (err) {
              // Using setTimeout here because:
              // https://stackoverflow.com/questions/30715367/why-can-i-not-throw-inside-a-promise-catch-handler
              // Fix this later
              console.warn(
                `[assets] Loading of ${item.key} failed with error: ${err}`
              );
              setTimeout(() => {
                throw err;
              }, 0);
            }

            count++;

            next(null, result);
          },
          queue
        );
      },
      () => {
        this._onLoadedListeners.forEach((fn) => fn(null));
        cb();
      }
    );
  }

  /**
   * Load asset, return from cache if it already exists.
   *
   * @param {Object} item
   * @param {function} cb
   */
  load(item, cb = noop) {
    const url = item.url;
    const ext = url.split('.').pop();
    const key = item.key || url;
    const onComplete = item.onComplete || null;
    const cache = this._cache;

    if (key in cache) {
      const data = cache[key];
      process.nextTick(() => {
        cb(null, data);
      });

      // check for oncomplete prop
      if (item.onComplete) onComplete(data);

      this._checkGrouped(item);
    } else {
      // console.log(`[assets] Loading ${url}`);

      const done = (err, data) => {
        if (err) {
          delete cache[key];
        } else {
          cache[key] = data;
        }

        if (this._finishDelay) {
          setTimeout(() => {
            cb(err, data);
            if (item.onComplete) onComplete(data);
          }, this._finishDelay);
        } else {
          cb(err, data);
          if (item.onComplete) onComplete(data);
        }

        this._checkGrouped(item);
      };

      switch (true) {
        // case isGLTF(ext):
        //   return this.loadGLTF(url, item, done);

        // case isFBX(ext):
        //   return this.loadFBX(url, item, done);

        case isSVG(ext):
        case isImage(ext):
        case isDataURL(url):
          return this.loadImage(url, item, done);

        default:
          throw new Error(`Could not load ${url}, unknown file extension!`);
      }
    }
  }

  /**
   * Load GLTF/GLB model data.
   *
   * @param {string} url
   * @param {Object} item
   * @param {function} done
   */
  // loadGLTF(url, item, done) {
  //   return loadGLTF(url, item)
  //     .then((obj) => {
  //       done(null, obj);
  //     })
  //     .catch((err) => {
  //       done(err, null);
  //     });
  // }

  // /**
  //  * Load FBX model data.
  //  *
  //  * @param {string} url
  //  * @param {Object} item
  //  * @param {function} done
  //  */
  // loadFBX(url, item, done) {
  //   return loadFBX(url, item)
  //     .then(obj => {
  //       // console.log('done', obj);
  //       done(null, obj);
  //     })
  //     .catch(err => {
  //       console.log('err', err);
  //       done(err, null);
  //     });
  // }

  /**
   * Load image.
   *
   * @param {string} url
   * @param {Object} item
   * @param {function} done
   *
   * @return {Image|Texture}
   */
  loadImage(url, item, done) {
    const key = item.key || url;

    let result;

    if (item.texture || item.compressedTexture) {
      if (!this._renderer) {
        throw new Error(
          `Could not load ${url} as texture, renderer was not set!`
        );
      }

      // result = item.compressedTexture
      //   ? loadCompressedTexture(
      //       url,
      //       { renderer: this._renderer, ...item },
      //       done
      //     )
      //   : loadTexture(url, { renderer: this._renderer, ...item }, done);
      result = loadTexture(url, { renderer: this._renderer, ...item }, done);
    } else {
      result = loadImage(url)
        .then((img) => {
          done(null, img);
        })
        .catch((err) => {
          done(err, null);
        });
    }

    this._cache[key] = result;

    return result;
  }

  dispose(key, cb = noop) {
    let item = this._cache[key];

    if (!item) {
      console.warn(`No item by the key "${key}" found in the cache.`);
      return;
    }

    switch (true) {
      case item instanceof Texture:
        item.dispose();
        break;

      case item instanceof Object3D ||
        item instanceof Scene ||
        item instanceof Mesh:
        destroyChildMeshesFrom(item);
        break;
    }

    item = null;
    delete this._cache[key];

    cb(null);
  }

  /**
   * Is the queue empty?
   *
   * @return {boolean}
   */
  isQueueEmpty() {
    return this._queue.length === 0;
  }

  /**
   * Is group loaded?
   *
   * @param {string} id
   */
  isGroupLoaded(id) {
    const group = this._groups[id];
    if (!group) return false;
    return group.loaded === group.total;
  }

  /**
   * Check if asset belongs to a group, and track the groups progress.
   *
   * @param {Object} item
   */
  _checkGrouped(item) {
    // check if item belongs to a group
    if (item.groups) {
      item.groups.forEach((groupId) => {
        const group = this._groups[groupId];

        if (!group) return;

        group.loaded++;

        if (this.isGroupLoaded(groupId)) {
          if (group.callback) group.callback();

          delete this._groups[groupId];
        }
      });
    }
  }

  /**
   * Get queued asset by key.
   *
   * @param {string} key
   *
   * @return {*}
   */
  _getQueued(key) {
    for (let i = 0; i < this._queue.length; i++) {
      const item = this._queue[i];

      if (item.key === key) return item;
    }

    return null;
  }

  /**
   * Add listener that needs to be notified on progress updates.
   *
   * @param {function} fn
   */
  addProgressListener(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('onProgress must be a function');
    }

    this._onProgressListeners.push(fn);
  }

  /**
   * Remove listener from progress notifications.
   *
   * @param {function} fn
   */
  removeProgressListener(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('onProgress must be a function');
    }

    const index = this._onProgressListeners.indexOf(fn);

    if (index === -1) {
      throw new TypeError(
        'No such listener registered, removed fn must be the same as added.'
      );
    }

    this._onProgressListeners.splice(index, 1);
  }

  /**
   * Add listener that needs to be notified when loading is done.
   *
   * @param {function} fn
   */
  addLoadedListener(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('onLoad must be a function');
    }

    this._onLoadedListeners.push(fn);
  }

  /**
   * Remove listener from loaded notifications.
   *
   * @param {function} fn
   */
  removeLoadedListener(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('onLoad must be a function');
    }

    const index = this._onLoadedListeners.indexOf(fn);

    if (index === -1) {
      throw new TypeError(
        'No such listener registered, removed fn must be the same as added.'
      );
    }

    this._onLoadedListeners.splice(index, 1);
  }
}

const assets = new AssetManager();

export default assets;
