/**
 * This file is part of the SIP application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { Object3D } from 'three/src/core/Object3D';
import * as THREE from 'three';

import assets from '../../../../utils/asset-manager';
import canUseWebP from '../../../../utils/canUseWebP';

import textureSrc from './textures/NSU_tex.png';

const webpSupported = canUseWebP();
const LOOK_AT_EFFECT_STRENGTH = 0.6;

/**
 * This is the Sphere scene.
 */
export default class Sphere extends Object3D {
  name = 'Sphere';
  loaded = false;
  isMobile = false;
  spheres = [
    {
      size: 15,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      rotation: {
        y: Math.PI * -0.5,
        x: 0,
      },
      rotationSpeed: 0.8,
      autoRotate: false,
    },
    {
      size: 12,
      position: {
        x: -20,
        y: 20,
        z: -175,
      },
      rotation: {
        y: Math.PI * -1.1,
        x: Math.PI * 0.1,
      },
      rotationSpeed: 0.6,
      autoRotate: true,
    },
    {
      size: 13,
      position: {
        x: 32,
        y: 20,
        z: -80,
      },
      rotation: {
        y: Math.PI * 1.4,
        x: Math.PI * -0.15,
      },
      rotationSpeed: -0.5,
      autoRotate: true,
    },
  ];

  constructor({ assetQueueId } = {}) {
    super();

    this.position.y = -5;

    const textureMapSrc = webpSupported ? textureSrc : textureSrc;

    assets.queueGroup(
      [
        {
          url: textureMapSrc,
          texture: true,
          needsUpdate: true,
        },
      ],
      assetQueueId || 'sphere',
      () => {
        const texture = assets.get(textureMapSrc);
        this.material = new THREE.MeshStandardMaterial({
          map: texture,
          color: new THREE.Color('#ffffff'),
          roughness: 0.5,
        });

        for (const sphere of this.spheres) {
          this.geometry = new THREE.SphereGeometry(sphere.size, 20, 20);
          sphere.mesh = new THREE.Mesh(this.geometry, this.material);

          sphere.mesh.position.x = sphere.position.x;
          sphere.mesh.position.y = sphere.position.y;
          sphere.mesh.position.z = sphere.position.z;

          sphere.mesh.rotation.y = sphere.rotation.y;
          sphere.mesh.rotation.x = sphere.rotation.x;
          this.add(sphere.mesh);
        }

        this.loaded = true;
      }
    );
  }

  /**
   * Update Sphere.
   */
  update = ({ elapsed, delta, scrollDelta, pointer }) => {
    if (!this.loaded) return;

    for (const sphere of this.spheres) {
      if (sphere.autoRotate) {
        sphere.mesh.rotation.y +=
          delta * sphere.rotationSpeed +
          Math.abs(scrollDelta.y) * (this.isMobile ? 0.02 : 0.01) * sphere.rotationSpeed;
      } else {
        // Offset the pointer to the left on desktop, because the balls are visually placed to the right
        const pointerOffsetX = this.isMobile ? 0 : -0.2;

        sphere.mesh.rotation.y =
          sphere.rotation.y + (pointer.x + pointerOffsetX) * LOOK_AT_EFFECT_STRENGTH;
        sphere.mesh.rotation.x = sphere.rotation.x + pointer.y * -1 * LOOK_AT_EFFECT_STRENGTH;

        if (this.isMobile) {
          sphere.mesh.rotation.y += Math.cos(elapsed * 0.5) * 0.2;
          sphere.mesh.rotation.x += Math.sin(elapsed * 0.5) * 0.2;
        }
      }

      sphere.mesh.position.y += scrollDelta.y * (sphere.mesh.position.z - 20) * 0.0005;
    }
  };

  resize = (resolution) => {
    this.isMobile = resolution.x < 960;
    if (this.isMobile) {
      this.position.x = 0;
      this.position.y = -15;
    } else {
      this.position.x = 20;
      this.position.y = -4;
    }
  };

  /**
   * Render Sphere.
   */
  render() {}

  /**
   * Dispose Sphere.
   */
  dispose() {
    console.log('Dispose Sphere');
  }
}
