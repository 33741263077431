/**
 * This file is part of the SIP application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-disable no-restricted-globals */

export const createWorker = (f) => {
  if (typeof window !== 'undefined') {
    return new window.Worker(
      window.URL.createObjectURL(new window.Blob([`(${f})()`]))
    );
  }
};

export const getWorkerBlob = createWorker(() => {
  self.addEventListener('message', (e) => {
    const src = e.data;

    // if (self.createImageBitmap) {
    //   fetch(src, { mode: 'cors', credentials: 'same-origin' })
    //     .then(response => response.blob())
    //     .then(blob =>
    //       self.createImageBitmap(blob, { imageOrientation: 'flipY' })
    //     )
    //     .then(bitmap => {
    //       self.postMessage({ src, bitmap }, [bitmap]);
    //     });
    // }

    if (self.fetch) {
      self
        .fetch(src, { mode: 'cors', credentials: 'same-origin' })
        .then((response) => response.blob())
        .then((blob) => self.URL.createObjectURL(blob))
        .then((blob) => {
          self.postMessage({ src, blob });
        });
    } else {
      let xhr = new self.XMLHttpRequest();

      if ('withCredentials' in xhr) {
        xhr.open('GET', src, true);
        xhr.withCredentials = true;
      } else if (typeof self.XDomainRequest !== 'undefined') {
        xhr.open('GET', src, true);
        xhr = new self.XDomainRequest();
      } else {
        self.postMessage({ error: 'Could not load image' });
        return;
      }

      xhr.responseType = 'blob';

      xhr.onreadystatechange = function (event) {
        if (event.target.readyState === 4) {
          if (event.target.status === 200 || event.target.status === 0) {
            const blob = self.URL.createObjectURL(this.response);
            self.postMessage({ src, blob });

            self.URL.revokeObjectURL(this.response);
          } else {
            self.postMessage({ error: 'Could not load image' });
          }
        }
      };

      xhr.send();
    }
  });
});

export const getWorkerArrayBuffer = createWorker(() => {
  self.addEventListener('message', (e) => {
    const src = e.data;

    if (self.fetch) {
      self
        .fetch(src, { mode: 'cors', credentials: 'same-origin' })
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          self.postMessage({ src, buffer }, [buffer]);
        });
    } else {
      let xhr = new self.XMLHttpRequest();

      if ('withCredentials' in xhr) {
        xhr.open('GET', src, true);
        xhr.withCredentials = true;
      } else if (typeof self.XDomainRequest !== 'undefined') {
        xhr = new self.XDomainRequest();
        xhr.open('GET', src, true);
      } else {
        self.postMessage({ error: 'Could not load image' });
        return;
      }

      xhr.responseType = 'arraybuffer';

      xhr.onreadystatechange = function (event) {
        if (event.target.readyState === 4) {
          if (event.target.status === 200 || event.target.status === 0) {
            self.postMessage({ src, buffer: this.response });
          } else {
            self.postMessage({ error: 'Could not load image' });
          }
        }
      };

      xhr.send();
    }
  });
});
