/*
	External dependencies
*/
import React from 'react';
import { graphql } from 'gatsby';

/*
	Components
*/
import App from '../components/App/App';
import Components from '../components/Components';
import Nav from '../components/Nav/Nav';
import HeroSphere from '../components/HeroSphere/HeroSphere';
import Footer from '../components/Footer/Footer';

const Home = ({ data }) => {
  const { title, contentBlocks, footer } = data.sanityWhyWeChallengePage;

  return (
    <App>
      <Nav />
      <HeroSphere title={title} />
      {contentBlocks.map((block) => Components(block))}
      <Footer data={footer} />
    </App>
  );
};

export const query = graphql`
  {
    sanityWhyWeChallengePage {
      title
      contentBlocks {
        ... on SanityAccordionSection {
          _key
          _type
          lead
          title
          accordion {
            text
            title
          }
        }
        ... on SanityCardGridSection {
          _key
          _type
          lead
          title
          _rawCardGrid
        }
        ... on SanityTextSection {
          _key
          _type
          _rawText
          bgcolor
          heading
        }
        ... on SanityVideoSection {
          _key
          _type
          ctaText
          video {
            asset {
              playbackId
              filename
            }
          }
          title
          mobileVideo {
            asset {
              playbackId
              filename
            }
          }
          poster {
            asset {
              url
            }
          }
        }
      }
      footer {
        text
        link {
          path
          title
        }
      }
    }
  }
`;

export default Home;
