import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import classNames from 'classnames';

import useInView from '../../hooks/useInView';
import World, { LOADED, PROGRESS } from './World';
import textWithWhiteSpace from '../../utils/textWithWhiteSpace';

import * as styles from './HeroSphere.module.scss';

const HeroSphere = ({ title }) => {
  const world = useRef();
  const container = useRef();
  const canvasContainer = useRef();
  const inView = useInView(container);
  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    if (!canvasContainer.current || world.current) return;

    const onProgress = ({ progress }) => {
      console.log(progress);
    };
    const onLoad = () => {
      world.current.removeEventListener(LOADED, onLoad);
      world.current.removeEventListener(PROGRESS, onProgress);
      setTimeout(() => {
        setLoaded(true);
      }, 10);
    };

    world.current = new World({
      container: canvasContainer.current,
    });
    world.current.addEventListener(PROGRESS, onProgress);
    world.current.addEventListener(LOADED, onLoad);
    world.current.load();

    return () => {
      if (world.current) {
        world.current.stop();
      }
      world.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (inView) {
      world.current.start();
    } else {
      world.current.stop();
    }
  }, [inView]);

  return (
    <div className={styles.main} ref={container}>
      <div
        className={classNames(styles.canvasContainer, {
          [styles.loaded]: loaded,
        })}
        ref={canvasContainer}
      />
      <div className={styles.fg}>
        <h1 className={styles.heading}>{textWithWhiteSpace(title)}</h1>
      </div>
    </div>
  );
};

export default HeroSphere;
