/**
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 */

// This set of controls performs orbiting, dollying (zooming), and panning.
// Unlike TrackballControls, it maintains the "up" direction object.up (+Y by default).
//
//    Orbit - left mouse / touch: one-finger move
//    Zoom - middle mouse, or mousewheel / touch: two-finger spread or squish
//    Pan - right mouse, or arrow keys / touch: two-finger move

import { MOUSE } from 'three/src/constants';
import { Vector2 } from 'three/src/math/Vector2';
import { Vector3 } from 'three/src/math/Vector3';
import { Quaternion } from 'three/src/math/Quaternion';
import { Spherical } from 'three/src/math/Spherical';
import { Raycaster } from 'three/src/core/Raycaster';
import { EventDispatcher } from 'three/src/core/EventDispatcher';

const STATE = {
  NONE: -1,
  ROTATE: 0,
  DOLLY: 1,
  PAN: 2,
  TOUCH_ROTATE: 3,
  TOUCH_DOLLY_PAN: 4,
};

export default class OrbitControls extends EventDispatcher {
  changeEvent = { type: 'change' };
  startEvent = { type: 'start' };
  endEvent = { type: 'end' };

  state = STATE.NONE;

  EPS = 0.000001;

  object = null;
  domElement = null;

  // Set to false to disable this control
  enabled = false;

  // "target" sets the location of focus, where the object orbits around
  target = new Vector3();

  // How far you can dolly in and out ( PerspectiveCamera only )
  minDistance = 0;
  maxDistance = Infinity;

  // How far you can zoom in and out ( OrthographicCamera only )
  minZoom = 0;
  maxZoom = Infinity;

  // How far you can orbit vertically, upper and lower limits.
  // Range is 0 to Math.PI radians.
  minPolarAngle = 0; // radians
  maxPolarAngle = Math.PI; // radians

  // How far you can orbit horizontally, upper and lower limits.
  // If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
  minAzimuthAngle = -Infinity; // radians
  maxAzimuthAngle = Infinity; // radians

  // Set to true to enable damping (inertia)
  // If damping is enabled, you must call controls.update() in your animation loop
  enableDamping = false;
  dampingFactor = 0.25;

  // This option actually enables dollying in and out; left as "zoom" for backwards compatibility.
  // Set to false to disable zooming
  enableZoom = true;
  zoomSpeed = 1.0;

  // Set to false to disable rotating
  enableRotate = true;
  rotateSpeed = 1.0;

  // Set to false to disable panning
  enablePan = true;
  panSpeed = 1.0;
  screenSpacePanning = false; // if true, pan in screen-space
  keyPanSpeed = 7.0; // pixels moved per arrow key push

  // Set to true to automatically rotate around the target
  // If auto-rotate is enabled, you must call controls.update() in your animation loop
  autoRotate = false;
  autoRotateSpeed = 2.0; // 30 seconds per round when fps is 60

  // Set to false to disable use of the keys
  enableKeys = true;

  // The four arrow keys
  keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 };

  // Mouse buttons
  mouseButtons = {
    ORBIT: MOUSE.LEFT,
    ZOOM: MOUSE.MIDDLE,
    PAN: MOUSE.RIGHT,
  };

  // for reset
  target0 = null;
  position0 = null;
  zoom0 = null;

  // current position in spherical coordinates
  spherical = new Spherical();
  sphericalDelta = new Spherical();

  scale = 1;
  panOffset = new Vector3();
  zoomChanged = false;

  rotateStart = new Vector2();
  rotateEnd = new Vector2();
  rotateDelta = new Vector2();

  panStart = new Vector2();
  panEnd = new Vector2();
  panDelta = new Vector2();

  dollyStart = new Vector2();
  dollyEnd = new Vector2();
  dollyDelta = new Vector2();

  useBounds = false;
  bounds = null;

  constructor(object, domElement) {
    super();

    this.object = object;

    this.domElement =
      domElement !== undefined ? domElement : global.document.body;

    // Set to false to disable this control
    this.enabled = true;

    // for reset
    this.target0 = this.target.clone();
    this.position0 = this.object.position.clone();
    this.zoom0 = this.object.zoom;

    this.update = this.update();

    this.bindEvents();
    this.update();
  }

  bindEvents() {
    this.domElement.addEventListener('contextmenu', this.onContextMenu, false);

    this.domElement.addEventListener('mousedown', this.onMouseDown, false);
    this.domElement.addEventListener('wheel', this.onMouseWheel, false);

    this.domElement.addEventListener('touchstart', this.onTouchStart, false);
    this.domElement.addEventListener('touchend', this.onTouchEnd, false);
    this.domElement.addEventListener('touchmove', this.onTouchMove, false);

    global.addEventListener('keydown', this.onKeyDown, false);
  }

  dispose = () => {
    this.domElement.removeEventListener(
      'contextmenu',
      this.onContextMenu,
      false
    );
    this.domElement.removeEventListener('mousedown', this.onMouseDown, false);
    this.domElement.removeEventListener('wheel', this.onMouseWheel, false);

    this.domElement.removeEventListener('touchstart', this.onTouchStart, false);
    this.domElement.removeEventListener('touchend', this.onTouchEnd, false);
    this.domElement.removeEventListener('touchmove', this.onTouchMove, false);

    document.removeEventListener('mousemove', this.onMouseMove, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);

    global.removeEventListener('keydown', this.onKeyDown, false);

    //this.dispatchEvent( { type: 'dispose' } ); // should this be added here?
  };

  getPolarAngle = () => {
    return this.spherical.phi;
  };

  getAzimuthalAngle = () => {
    return this.spherical.theta;
  };

  setPolarAngle = (angle) => {
    this.spherical.phi = angle;
  };

  setAzimuthalAngle = (angle) => {
    this.spherical.theta = angle;
  };

  rotateLeft = (angle) => {
    this.sphericalDelta.theta -= angle;
  };

  rotateUp = (angle) => {
    this.sphericalDelta.phi -= angle;
  };

  setRotateUpDelta = (angle) => {
    this.sphericalDelta.phi = angle;
  };

  saveState = () => {
    this.target0.copy(this.target);
    this.position0.copy(this.object.position);
    this.zoom0 = this.object.zoom;
  };

  reset = () => {
    this.target.copy(this.target0);
    this.object.position.copy(this.position0);
    this.object.zoom = this.zoom0;

    this.object.updateProjectionMatrix();
    this.dispatchEvent(this.changeEvent);

    this.update();

    this.state = STATE.NONE;
  };

  // this method is exposed, but perhaps it would be better if we can make it private...
  update = () => {
    const offset = new Vector3();

    // so camera.up is the orbit axis
    const quat = new Quaternion().setFromUnitVectors(
      this.object.up,
      new Vector3(0, 1, 0)
    );
    const quatInverse = quat.clone().inverse();

    const nextPosition = new Vector3();

    const raycaster = new Raycaster();
    const directionVector = new Vector3();

    const lastPosition = new Vector3();
    const lastQuaternion = new Quaternion();

    return () => {
      const { position } = this.object;

      offset.copy(position).sub(this.target);

      // rotate offset to "y-axis-is-up" space
      offset.applyQuaternion(quat);

      // angle from z-axis around y-axis
      this.spherical.setFromVector3(offset);

      if (this.autoRotate && this.state === STATE.NONE) {
        this.rotateLeft(this.getAutoRotationAngle());
      }

      this.spherical.theta += this.sphericalDelta.theta;
      this.spherical.phi += this.sphericalDelta.phi;

      // restrict theta to be between desired limits
      this.spherical.theta = Math.max(
        this.minAzimuthAngle,
        Math.min(this.maxAzimuthAngle, this.spherical.theta)
      );

      // restrict phi to be between desired limits
      this.spherical.phi = Math.max(
        this.minPolarAngle,
        Math.min(this.maxPolarAngle, this.spherical.phi)
      );

      this.spherical.makeSafe();

      this.spherical.radius *= this.scale;

      // restrict radius to be between desired limits
      this.spherical.radius = Math.max(
        this.minDistance,
        Math.min(this.maxDistance, this.spherical.radius)
      );

      // move target to panned location
      this.target.add(this.panOffset);

      offset.setFromSpherical(this.spherical);

      // rotate offset back to "camera-up-vector-is-up" space
      offset.applyQuaternion(quatInverse);

      nextPosition.copy(this.target).add(offset);

      // Raycast against next position to determine if distance to bounds is
      // less than limit. If so, discard update! (or change direction?)
      if (this.useBounds && this.bounds) {
        directionVector.copy(nextPosition).sub(position);
        raycaster.set(position, directionVector.clone().normalize());

        const intersectBounds = raycaster.intersectObjects(
          this.bounds.children
        )[0];

        if (
          intersectBounds &&
          intersectBounds.distance <= 4 + directionVector.length()
        ) {
          // const vertexIndex = intersectBounds.faceIndex * 3;
          // const normals =
          //   intersectBounds.object.geometry.attributes.normal.array;
          // const normalX = normals[vertexIndex + 0];
          // const normalY = normals[vertexIndex + 1];
          // const normalZ = normals[vertexIndex + 2];

          // // console.log(intersectBounds.object.geometry.attributes.normal.array[intersectBounds.faceIndex ]);

          // this.sphericalDelta.set(
          //   normalX * 0.005,
          //   normalY * 0.005,
          //   normalZ * 0.005
          // );
          this.sphericalDelta.set(0, 0, 0);
          this.panOffset.set(0, 0, 0);
          this.scale = 1;

          // this.spherical.radius += 40;

          // offset.setFromSpherical(this.spherical);

          // // rotate offset back to "camera-up-vector-is-up" space
          // offset.applyQuaternion(quatInverse);

          // nextPosition.copy(this.target).add(offset);

          // position.copy(nextPosition);

          return;
        }
      }

      position.copy(nextPosition);

      this.object.lookAt(this.target);

      if (this.enableDamping === true) {
        this.sphericalDelta.theta *= 1 - this.dampingFactor;
        this.sphericalDelta.phi *= 1 - this.dampingFactor;

        this.panOffset.multiplyScalar(1 - this.dampingFactor);
      } else {
        this.sphericalDelta.set(0, 0, 0);

        this.panOffset.set(0, 0, 0);
      }

      this.scale = 1;

      // update condition is:
      // min(camera displacement, camera rotation in radians)^2 > EPS
      // using small-angle approximation cos(x/2) = 1 - x^2 / 8

      if (
        this.zoomChanged ||
        lastPosition.distanceToSquared(this.object.position) > this.EPS ||
        8 * (1 - lastQuaternion.dot(this.object.quaternion)) > this.EPS
      ) {
        this.dispatchEvent(this.changeEvent);

        lastPosition.copy(this.object.position);
        lastQuaternion.copy(this.object.quaternion);
        this.zoomChanged = false;

        return true;
      }

      return false;
    };
  };

  getAutoRotationAngle = () => {
    return ((2 * Math.PI) / 60 / 60) * this.autoRotateSpeed;
  };

  getZoomScale = () => {
    return Math.pow(0.95, this.zoomSpeed);
  };

  rotateLeft = (angle) => {
    this.sphericalDelta.theta -= angle;
  };

  rotateUp = (angle) => {
    this.sphericalDelta.phi -= angle;
  };

  panLeft = (() => {
    var v = new Vector3();

    return (distance, objectMatrix) => {
      v.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
      v.multiplyScalar(-distance);

      this.panOffset.add(v);
    };
  })();

  panUp = (() => {
    var v = new Vector3();

    return (distance, objectMatrix) => {
      if (this.screenSpacePanning === true) {
        v.setFromMatrixColumn(objectMatrix, 1);
      } else {
        v.setFromMatrixColumn(objectMatrix, 0);
        v.crossVectors(this.object.up, v);
      }

      v.multiplyScalar(distance);

      this.panOffset.add(v);
    };
  })();

  // deltaX and deltaY are in pixels; right and down are positive
  pan = (() => {
    const offset = new Vector3();

    return (deltaX, deltaY) => {
      if (this.object.isPerspectiveCamera) {
        // perspective
        const position = this.object.position;
        offset.copy(position).sub(this.target);
        let targetDistance = offset.length();

        // half of the fov is center to top of screen
        targetDistance *= Math.tan(((this.object.fov / 2) * Math.PI) / 180.0);

        // we use only clientHeight here so aspect ratio does not distort speed
        this.panLeft(
          (2 * deltaX * targetDistance) / this.domElement.clientHeight,
          this.object.matrix
        );
        this.panUp(
          (2 * deltaY * targetDistance) / this.domElement.clientHeight,
          this.object.matrix
        );
      } else if (this.object.isOrthographicCamera) {
        // orthographic
        this.panLeft(
          (deltaX * (this.object.right - this.object.left)) /
            this.object.zoom /
            this.domElement.clientWidth,
          this.object.matrix
        );
        this.panUp(
          (deltaY * (this.object.top - this.object.bottom)) /
            this.object.zoom /
            this.domElement.clientHeight,
          this.object.matrix
        );
      } else {
        // camera neither orthographic nor perspective
        console.warn(
          'WARNING: OrbitControls.js encountered an unknown camera type - pan disabled.'
        );
        this.enablePan = false;
      }
    };
  })();

  dollyIn = (dollyScale) => {
    if (this.object.isPerspectiveCamera) {
      this.scale /= dollyScale;
    } else if (this.object.isOrthographicCamera) {
      this.object.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.object.zoom * dollyScale)
      );
      this.object.updateProjectionMatrix();
      this.zoomChanged = true;
    } else {
      console.warn(
        'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.'
      );
      this.enableZoom = false;
    }
  };

  dollyOut = (dollyScale) => {
    if (this.object.isPerspectiveCamera) {
      this.scale *= dollyScale;
    } else if (this.object.isOrthographicCamera) {
      this.object.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.object.zoom / dollyScale)
      );
      this.object.updateProjectionMatrix();
      this.zoomChanged = true;
    } else {
      console.warn(
        'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.'
      );
      this.enableZoom = false;
    }
  };

  //
  // event callbacks - update the object state
  //

  handleMouseDownRotate = (event) => {
    //console.log( 'handleMouseDownRotate' );

    this.rotateStart.set(event.clientX, event.clientY);
  };

  handleMouseDownDolly = (event) => {
    //console.log( 'handleMouseDownDolly' );

    this.dollyStart.set(event.clientX, event.clientY);
  };

  handleMouseDownPan = (event) => {
    //console.log( 'handleMouseDownPan' );

    this.panStart.set(event.clientX, event.clientY);
  };

  handleMouseMoveRotate = (event) => {
    //console.log( 'handleMouseMoveRotate' );

    this.rotateEnd.set(event.clientX, event.clientY);

    this.rotateDelta
      .subVectors(this.rotateEnd, this.rotateStart)
      .multiplyScalar(this.rotateSpeed);

    var element =
      this.domElement === document ? this.domElement.body : this.domElement;

    this.rotateLeft((2 * Math.PI * this.rotateDelta.x) / element.clientHeight); // yes, height

    this.rotateUp((2 * Math.PI * this.rotateDelta.y) / element.clientHeight);

    this.rotateStart.copy(this.rotateEnd);

    this.update();
  };

  handleMouseMoveDolly = (event) => {
    //console.log( 'handleMouseMoveDolly' );

    this.dollyEnd.set(event.clientX, event.clientY);

    this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);

    if (this.dollyDelta.y > 0) {
      this.dollyIn(this.getZoomScale());
    } else if (this.dollyDelta.y < 0) {
      this.dollyOut(this.getZoomScale());
    }

    this.dollyStart.copy(this.dollyEnd);

    this.update();
  };

  handleMouseMovePan = (event) => {
    //console.log( 'handleMouseMovePan' );

    this.panEnd.set(event.clientX, event.clientY);

    this.panDelta
      .subVectors(this.panEnd, this.panStart)
      .multiplyScalar(this.panSpeed);

    this.pan(this.panDelta.x, this.panDelta.y);

    this.panStart.copy(this.panEnd);

    this.update();
  };

  handleMouseUp = (event) => {
    // console.log( 'handleMouseUp' );
  };

  handleMouseWheel = (event) => {
    // console.log( 'handleMouseWheel' );

    if (event.deltaY < 0) {
      this.dollyOut(this.getZoomScale());
    } else if (event.deltaY > 0) {
      this.dollyIn(this.getZoomScale());
    }

    this.update();
  };

  handleKeyDown = (event) => {
    // console.log( 'handleKeyDown' );

    switch (event.keyCode) {
      case this.keys.UP:
        this.pan(0, this.keyPanSpeed);
        this.update();
        break;

      case this.keys.BOTTOM:
        this.pan(0, -this.keyPanSpeed);
        this.update();
        break;

      case this.keys.LEFT:
        this.pan(this.keyPanSpeed, 0);
        this.update();
        break;

      case this.keys.RIGHT:
        this.pan(-this.keyPanSpeed, 0);
        this.update();
        break;
    }
  };

  handleTouchStartRotate = (event) => {
    //console.log( 'handleTouchStartRotate' );

    this.rotateStart.set(event.touches[0].pageX, event.touches[0].pageY);
  };

  handleTouchStartDollyPan = (event) => {
    //console.log( 'handleTouchStartDollyPan' );

    if (this.enableZoom) {
      var dx = event.touches[0].pageX - event.touches[1].pageX;
      var dy = event.touches[0].pageY - event.touches[1].pageY;

      var distance = Math.sqrt(dx * dx + dy * dy);

      this.dollyStart.set(0, distance);
    }

    if (this.enablePan) {
      var x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
      var y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);

      this.panStart.set(x, y);
    }
  };

  handleTouchMoveRotate = (event) => {
    //console.log( 'handleTouchMoveRotate' );

    this.rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY);

    this.rotateDelta
      .subVectors(this.rotateEnd, this.rotateStart)
      .multiplyScalar(this.rotateSpeed);

    var element =
      this.domElement === document ? this.domElement.body : this.domElement;

    this.rotateLeft((2 * Math.PI * this.rotateDelta.x) / element.clientHeight); // yes, height

    this.rotateUp((2 * Math.PI * this.rotateDelta.y) / element.clientHeight);

    this.rotateStart.copy(this.rotateEnd);

    this.update();
  };

  handleTouchMoveDollyPan = (event) => {
    //console.log( 'handleTouchMoveDollyPan' );

    if (this.enableZoom) {
      var dx = event.touches[0].pageX - event.touches[1].pageX;
      var dy = event.touches[0].pageY - event.touches[1].pageY;

      var distance = Math.sqrt(dx * dx + dy * dy);

      this.dollyEnd.set(0, distance);

      this.dollyDelta.set(
        0,
        Math.pow(this.dollyEnd.y / this.dollyStart.y, this.zoomSpeed)
      );

      this.dollyIn(this.dollyDelta.y);

      this.dollyStart.copy(this.dollyEnd);
    }

    if (this.enablePan) {
      var x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
      var y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);

      this.panEnd.set(x, y);

      this.panDelta
        .subVectors(this.panEnd, this.panStart)
        .multiplyScalar(this.panSpeed);

      this.pan(this.panDelta.x, this.panDelta.y);

      this.panStart.copy(this.panEnd);
    }

    this.update();
  };

  handleTouchEnd = (event) => {
    //console.log( 'handleTouchEnd' );
  };

  //
  // event handlers - FSM: listen for events and reset state
  //

  onMouseDown = (event) => {
    if (this.enabled === false) return;

    // event.preventDefault();

    switch (event.button) {
      case this.mouseButtons.ORBIT:
        if (this.enableRotate === false) return;

        this.handleMouseDownRotate(event);

        this.state = STATE.ROTATE;

        break;

      case this.mouseButtons.ZOOM:
        if (this.enableZoom === false) return;

        this.handleMouseDownDolly(event);

        this.state = STATE.DOLLY;

        break;

      case this.mouseButtons.PAN:
        if (this.enablePan === false) return;

        this.handleMouseDownPan(event);

        this.state = STATE.PAN;

        break;
    }

    if (this.state !== STATE.NONE) {
      document.addEventListener('mousemove', this.onMouseMove, false);
      document.addEventListener('mouseup', this.onMouseUp, false);

      this.dispatchEvent(this.startEvent);
    }
  };

  onMouseMove = (event) => {
    if (this.enabled === false) return;

    // event.preventDefault();

    switch (this.state) {
      case STATE.ROTATE:
        if (this.enableRotate === false) return;

        this.handleMouseMoveRotate(event);

        break;

      case STATE.DOLLY:
        if (this.enableZoom === false) return;

        this.handleMouseMoveDolly(event);

        break;

      case STATE.PAN:
        if (this.enablePan === false) return;

        this.handleMouseMovePan(event);

        break;
    }
  };

  onMouseUp = (event) => {
    if (this.enabled === false) return;

    this.handleMouseUp(event);

    document.removeEventListener('mousemove', this.onMouseMove, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);

    this.dispatchEvent(this.endEvent);

    this.state = STATE.NONE;
  };

  onMouseWheel = (event) => {
    if (
      this.enabled === false ||
      this.enableZoom === false ||
      (this.state !== STATE.NONE && this.state !== STATE.ROTATE)
    )
      return;

    event.preventDefault();
    event.stopPropagation();

    this.dispatchEvent(this.startEvent);

    this.handleMouseWheel(event);

    this.dispatchEvent(this.endEvent);
  };

  onKeyDown = (event) => {
    if (
      this.enabled === false ||
      this.enableKeys === false ||
      this.enablePan === false
    )
      return;

    this.handleKeyDown(event);
  };

  onTouchStart = (event) => {
    if (this.enabled === false) return;

    // event.preventDefault();

    switch (event.touches.length) {
      case 1: // one-fingered touch: rotate
        if (this.enableRotate === false) return;

        this.handleTouchStartRotate(event);

        this.state = STATE.TOUCH_ROTATE;

        break;

      case 2: // two-fingered touch: dolly-pan
        if (this.enableZoom === false && this.enablePan === false) return;

        event.preventDefault();

        this.handleTouchStartDollyPan(event);

        this.state = STATE.TOUCH_DOLLY_PAN;

        break;

      default:
        this.state = STATE.NONE;
    }

    if (this.state !== STATE.NONE) {
      this.dispatchEvent(this.startEvent);
    }
  };

  onTouchMove = (event) => {
    if (this.enabled === false) return;

    // event.preventDefault();
    // event.stopPropagation();

    switch (event.touches.length) {
      case 1: // one-fingered touch: rotate
        if (this.enableRotate === false) return;
        if (this.state !== STATE.TOUCH_ROTATE) return; // is this needed?

        this.handleTouchMoveRotate(event);

        break;

      case 2: // two-fingered touch: dolly-pan
        if (this.enableZoom === false && this.enablePan === false) return;
        if (this.state !== STATE.TOUCH_DOLLY_PAN) return; // is this needed?

        event.preventDefault();
        event.stopPropagation();

        this.handleTouchMoveDollyPan(event);

        break;

      default:
        this.state = STATE.NONE;
    }
  };

  onTouchEnd = (event) => {
    if (this.enabled === false) return;

    this.handleTouchEnd(event);

    this.dispatchEvent(this.endEvent);

    this.state = STATE.NONE;
  };

  onContextMenu = (event) => {
    if (this.enabled === false) return;

    event.preventDefault();
  };
}
